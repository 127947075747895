/*hover*/
.btn.bg-light-blue-zp:hover{
    background: var(--main-zapp);
    color: #fff;
}
.btn.bg-orange-zp:hover{
    background: var(--light-blue-zapp);
    color: #fff;
}
.btn.bg-light-blue-zp:hover i,
.btn.bg-orange-zp:hover i{
    color: #fff !important;
}
.btn.bg-transparent:hover{
    background-color: var(--main-zapp) !important;
    color: #fff !important;
    border: none !important;
}
a:hover {
    color: var(--main-zapp);
}
.nav-link:focus, .nav-link:hover {
    color: var(--main-zapp);
}
.form-check-input:checked {
    background-color: var(--cl-hover);
    border-color: var(--cl-hover);
}
[data-layout=horizontal] .navbar-nav .nav-item:hover>.nav-link{
    color: var(--main-zapp);
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: var(--main-zapp);
}

/*table*/
table tbody tr:hover{
    background: var(--main-bland-zapp);
}
/*scroll*/
.scroll-content .modal-body:hover::-webkit-scrollbar-thumb
{
    background: #F1F1F5;
}

.siderbar-nav-left .active a,
.siderbar-nav-left ul li:hover a{
    color: var(--cl-hover);
    font-weight: 600;
}
.menu-dropdown .active a{
    color: var(--main-zapp) !important;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .menu-dropdown .nav-link:hover{
    color: var(--main-zapp) !important;
}
.btn-light:hover{
    background: var(--main-zapp);
    color: #fff;
}
.cursor-pointer i:hover{
    color: var(--main-zapp);
}
a i:hover{
    color: var(--main-zapp);
}