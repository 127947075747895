/*body*/
[data-layout=horizontal] body{
    color: var(--main-font-zapp);
    background: #ffffff;
    font-size: 14px !important;
    font-weight: 400;
    font-family: "Poppins" !important;
}
section{
    position: relative;
}
.page-content{
    background: #ffffff;
}
/*[data-layout=horizontal] .container-fluid, [data-layout=horizontal] .layout-width {*/
/*    max-width: 1600px;*/
/*    margin: 0 auto;*/
/*}*/
.container{
    margin: auto;
}
[data-layout=horizontal] .container-fluid, [data-layout=horizontal] .layout-width,
.container-detail{
    max-width: 1600px;
    margin: 0 auto;
}
.content-detail{
    max-width: 800px;
}
.show{
    display: block !important;
}
table tr th{
    text-transform: uppercase;
}
/*background body*/
.auth-bg-cover-ams {
    background: linear-gradient(342.24deg, #EDF9F1 0.15%, #E6FCDC 63.2%, #FEF1EC 124.61%);
}
.bg-short:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
    left: 0;
    z-index: 0;
}
.bg-br-before:before{
    background: var(--bg-br-ams);
}
.bg-or-before:before{
    background: var(--cl-or);
}
/*color and background default*/
.cl-font-zp{
    color: var(--main-font-zapp) !important;
}
.cl-orange-zp{
    color: var(--main-zapp);
}
.cl-icon-hd{
    color: var(--cl-icon-hd) !important;
}
.bg-orange-zp{
    background: var(--main-zapp);
}
.bg-orange-bland-zp{
    background: var(--main-bland-zapp);
}
.bg-light-blue-zp{
    background: var(--light-blue-zapp);
}
.bg-light-brown-zp{
    background: var(--light-brown-zapp);
}
.bg-search{
    background: var(--bg-search-zapp);
}
.cl-bdd{
    color: var(--cl-bdd);
}
/*button*/
.btn-lg-ams{
    /*border: 1px solid var(--bg-br-ams);*/
    border-radius: 10px;
}
.btn-xs-ams{
    /*border: 1px solid var(--bg-br-ams);*/
    border-radius: 5px;
}
.icon-btn{
    height: 20px;
    width: 20px;
    /*border: 1px solid var(--bg-br-ams);*/
    /*color: var(--bg-br-ams);*/
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-group-sm>.btn, .btn-sm{
    padding: 5px 15px;
    height: 28px;
    font-size: 14px;
    line-height: 15px;
}
.btn-border{
    border: 1px solid var(--cl-bdd) !important;
}
.btn-bd{
    border: 1px solid var(--cl-bd) !important;
}
.bd-none{
    border: none !important;
}
.bd-right{
    border-right: 1px solid var(--cl-bd);
}
.bdb-right{
    border-right: 1px solid var(--cl-bdd) !important;
}
.bd-left{
    border-left: 1px solid var(--cl-bd);
}
.bd-top{
    border-top: 1px solid var(--cl-bd);
}
.bd-bottom{
    border-bottom: 1px solid var(--cl-bd);
}
.bdb-bottom{
    border-bottom: 1px solid var(--cl-bdd);
}
.bdr-0{
    border-radius: 0 !important;
}
.bdr-r10{
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.bdr-4{
    border-radius: 4px;
}
.bdr-7{
    border-radius: 7px;
}
.bdr-10{
    border-radius: 10px !important;
}
.bdr-20{
    border-radius: 20px;
}
.btn-end-bottom{
    position: absolute;
    bottom: 0;
    right: 0;
}
.btn-end-top{
    position: absolute;
    top: 0;
    right: 0;
}
.btn-light{
    background: var(--btn-cancel);
    height: 30px;
}
.border-df{
    border: 1px solid var(--cl-bd);
}
.border-right-ams{
    border-right: 1px solid var(--cl-bd);
}
.border-left-ams{
    border-left: 1px solid var(--cl-bd);
}
.border-bottom-ams{
    border-bottom: 1px solid var(--cl-bd);
}
.box-shadow-ams{
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
}
/*font*/
.font-weight-normal{
    font-weight: normal;
}
.lh-0{
    line-height: 0px;
}
.lh-10{
    line-height: 10px;
}
.lh-15{
    line-height: 15px;
}
.lh-18{
    line-height: 18px;
}
.lh-20{
    line-height: 20px;
}
.lh-23{
    line-height: 23px;
}
.lh-25{
    line-height: 25px;
}
.lh-30{
    line-height: 30px;
}
.lh-35{
    line-height: 35px;
}

/*margin*/
.ml-0 {
    margin-left: 0!important;
}
.ml-1 {
    margin-left: 0.25rem!important;
}
.ml-2 {
    margin-left: 0.5rem!important;
}
.ml-3{
    margin-left: 1rem!important;
}
.ml-4{
    margin-left: 1.5rem!important;
}
.mr-0 {
    margin-right: 0!important;
}
.mr-1 {
    margin-right: 0.25rem!important;
}
.mr-2 {
    margin-right: 0.5rem!important;
}
.mr-3{
    margin-right: 1rem!important;
}
.mr-4{
    margin-right: 1.5rem!important;
}
/*padding*/
.pd-50{
    padding: 50px;
}
.pd-25{
    padding: 25px;
}
.pl-0{
    padding-left: 0;
}
.pl-20{
    padding-left: 20px;
}
.pl-10{
    padding-left: 10px;
}
.p02{
    padding: 0 20px;
}
/*card*/
.card-header{
    padding: 0;
}

/*text*/
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}

.h-70{
    height: 70px;
}
.w-5p{
    width: 5%;
}
.w-10p{
    width: 10%;
}
.w-15p{
    width: 15%;
}
.w-20p{
    width: 20%;
}
.w-25p{
    width: 25%;
}
.w-140{
    width: 140px;
    min-width: 140px;
}
.w-180{
    width: 180px;
    min-width: 180px;
}
.w-195{
    width: 195px;
    min-width: 195px;
}
.w-585{
    width: 585px;
    min-width: 585px;
}
.w-450{
    width: 450px;
}
.w-4x{
    width: 400px;
}
h1,h2,h3,h4,h5,h6{
    color: var(--cl-ams);
}

/*ul li*/
.list-style-none{
    list-style: none;
}

/*table*/
.border-collapse-unset{
    border-collapse: unset;
}
.table td,.table th{
    padding: 5px 8px;
    height: 36px;
}
.table-active {
    --vz-table-accent-bg: none;
    background: var(--main-bland-zapp);
}
/*select option*/
.form-select{
    /*background-image: url("../css/image/select1.png");*/
    /*background-size: auto;*/
}
/*input*/
::-webkit-input-placeholder { /* Edge */
    color: var(--cl-placeholder) !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--cl-placeholder) !important;
}

input::placeholder {
    color: var(--cl-placeholder) !important;
}
.search-box input::-webkit-input-placeholder { /* Edge */
    color: var(--cl-bdd) !important;
    font-size: 12px;
}

.search-box input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--cl-bdd) !important;
    font-size: 12px;
}

.search-box input::placeholder {
    color: var(--cl-bdd) !important;
    font-size: 12px;
}
.search-box .search-icon{
    color: var(--cl-bdd) ;
}
.search-box input{
    height: 28px;
}
.up-label label{
    text-transform: uppercase;
}
.form-select.form-select-zp{
    border-radius: unset;
    border: none;
    border-bottom: 1px solid var(--vz-input-border);
    padding-left: 0;
}
.form-control.form-control-zp{
    border: none;
    border-bottom: 1px solid var(--vz-input-border);
    border-radius: 0;
    padding-left: 0;
}
.form-check .form-check-input{
    font-size: 14px;
}
input:disabled {
    background-color: #fff !important;
}
/*icon*/
.mdi,.las{
    color: var(--cl-bdd);
}
a{
    color: var(--main-font-zapp);
    font-size: 14px;
}

/*modal*/
.modal-mb{
    width: 750px;
    max-width: 750px;
}
.card-header{
    border-bottom: 1px solid var(--cl-bd);
    padding-bottom: 5px;
}

.arrow-right,
.arrow-left{
    display: block;
    margin: 0px auto;
    width: 8px;
    height: 8px;
    border-top: 1px solid var(--cl-bdd);
    border-left: 1px solid var(--cl-bdd);
    position: relative;
    top: 5px;
}
.arrow-right{
    transform: rotate(135deg);
    right: 2px;
}

.arrow-left{
    transform: rotate(-45deg);
    left: 2px;
}
.page-link{
    height: 28px;
}
.fw-bold {
    font-weight: 600!important;
}
.fw-400{
    font-weight: 400!important;
}
.fw-500{
    font-weight: 500!important;
}
[data-layout=horizontal] .navbar-menu .navbar-nav .nav-link{
    color: var(--main-font-zapp);
    font-weight: 400;
}
.navbar-menu .navbar-nav .nav-link{
    font-family: "Poppins" !important;
}

.bg-brown {
    background: var(--cl-bd);
    color: var(--main-font-zapp);
}

.bg-done {
    background: #6EAE7C;
    color: #fff;
}
/*label*/
.form-label{
    font-weight: 600;
}