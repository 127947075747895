/*input*/
.btn-group-sm>.btn,
.input-sm{
    padding: 3px 15px;
    height: 28px;
}
/*button*/
.btn-lg-ams{
    border: 1px solid var(--bg-br-ams);
    border-radius: 10px;
}
.btn-xs-ams{
    border: 1px solid var(--bg-br-ams);
    border-radius: 5px;
}
.icon-btn{
    height: 20px;
    width: 20px;
    border: 1px solid var(--bg-br-ams);
    color: var(--bg-br-ams);
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-border{
    border: 1px solid var(--cl-bdd);
}
.btn-end-bottom{
    position: absolute;
    bottom: 0;
    right: 0;
}
.btn-end-top{
    position: absolute;
    top: 0;
    right: 0;
}
.btn-st.active{
    color: var(--main-zapp);
    font-weight: 600;
}
.btn.btn-sm{
    border-radius: 7px;
}
.btn.bg-orange-zp i,
.btn.bg-orange-zp{
    color: var(--orange-lg-font-zapp);
}
.btn.bg-light-blue-zp i,
.btn.bg-light-blue-zp{
    color: #fff;
}
.btn .ri-send-plane-fill{
    position: relative;
    top: 2px;
    padding-right: 7px;
}

/*status*/

.status-open{
    background: #b5b5be33;
    color: #92929D;
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
}
.status-on-hold{
    background: var(--orange-lg-font-zapp);
    color: var(--main-zapp);
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
}
.status-shipped{
    background: var(--bright-green-zapp);
    color: #013939;
    font-size: 12px;
    border-radius: 5px;
    text-align: center;
}