/*rps*/
@media (min-width: 1024px){
    [data-layout=horizontal] .page-content {
        margin-top: 40px;
    }
}
@media (min-width: 1650px){
    .header-detail, .navbar-header {
        padding: 0;
    }
}